
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

// Stores reusable objects like buttons, icons, that are used directly as class inside html (Source: _objects.scss)

// webks: Tools > overlay
.webks-tools-page-overlay{
  @include overlay;
}

// =============================================================================
//  Bootstrap Objects (included from libraries/bootstrap-sass/assets/stylesheets/bootstrap)
// =============================================================================

// TODO: We just import all BS mixins inside the _base.scss - is this stell needed?
@import 'libraries/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns';

// =============================================================================
//  Foundation Dropdown Panes
// =============================================================================

.dropdown-pane {
  background-color: $white;
  border: 1px solid $grey-light;
  border-radius: $border-radius;
  display: block;
  font-size: 1rem;
  padding: $thin-grid;
  position: absolute;
  visibility: hidden;
  width: 300px;
  z-index: 10;
  &.is-open {
    visibility: visible;
  }
  &.tiny { width: 100px; }
  &.small { width: 200px; }
  &.large { width: 400px; }
}

// =============================================================================
//  Buttons
// =============================================================================

.btn,
.button, // This class is used by some modules
.btn-inside a{
  @include btn;
}

// -- COLORS
// -- -- Green
.btn--sucess,
.btn-inside--sucess a{
  @include btn-mod('green');
}

// -- -- Red
.btn--danger,
.btn-inside--danger a{
  @include btn-mod('red');
}

// -- -- Ghost
.btn--ghost,
.btn-inside--ghost a{
  @include btn-mod('ghost');
}

// -- -- Disabled
.btn--disabled,
.btn-inside--disabled a,
button:disabled,
input[type="submit"]:disabled{
  @include btn-mod('disabled');
}

// -- -- Inverse
.btn--inverse,
.btn-inside--inverse a{
  @include btn-mod('inverted');
}

// -- -- Naked (no style, just an link with touch friendly size)
.btn--naked,
.btn-inside--naked a{
  @include btn-mod('naked');
}

// -- -- Kleinemeier: Gold
.btn--gold{
  background-color: #c2a555 !important;
  border-color: darken(#c2a555, 3%) !important;
  color:white !important;
  &:hover{
    background-color: lighten(#c2a555, 3%) !important;
    border-color: #c2a555 !important;
    color:white !important;
  }
  &:active,
  &:focus{
    background-color: darken(#c2a555, 3%) !important;
    border-color: #c2a555 !important;
    color:white !important;
  }
}

// -- -- Special: Financing - CTA color from the RCI bank
.btn--financing{
  background-color: #FF7900 !important;
  border-color: darken(#FF7900, 3%) !important;
  color:white !important;
  &:hover{
    background-color: lighten(#FF7900, 3%) !important;
    border-color: #FF7900 !important;
    color:white !important;
  }
  &:active,
  &:focus{
    background-color: darken(#FF7900, 3%) !important;
    border-color: #FF7900 !important;
    color:white !important;
  }
}

// -- Sizes
.btn--xs{
  @include btn-size(xs);
}
.btn--xl{
  @include btn-size(xl);
}
.btn--xxl{
  @include btn-size(xxl);
}
.btn--xxxl{
  @include btn-size(xxxl);
}

// Circle Buttons
.btn--circle{
  text-indent:-9000px;
  text-align:left;
  border-radius:50%;
  height:$icon-size-normal + ($btn-default-v-space * 2);
  width:$icon-size-normal + ($btn-default-v-space * 2);
  line-height:$icon-size-normal + ($btn-default-v-space * 2);
  padding:0 !important;
  &::before{
    float:left;
    text-indent:0;
    font-size:$icon-size-normal;
    vertical-align:middle;
    width:$icon-size-normal + ($btn-default-v-space * 2);
    line-height:$icon-size-normal + ($btn-default-v-space * 2) !important;
    text-align:center;
    margin:0 0 0 -1px !important;
  }
  &.btn--xs{
    height:$icon-size-xs + ($btn-xs-v-space * 2);
    width:$icon-size-xs + ($btn-xs-v-space * 2);
    line-height:$icon-size-xs + ($btn-xs-v-space * 2);
    &::before{
      font-size:$icon-size-xs;
      height:$icon-size-xs + ($btn-xs-v-space * 2);
      width:$icon-size-xs + ($btn-xs-v-space * 2);
      line-height:$icon-size-xs + ($btn-xs-v-space * 2) !important;
    }
  }
  &.btn--xl{
    height:$icon-size-xl + ($btn-xl-v-space * 2);
    width:$icon-size-xl + ($btn-xl-v-space * 2);
    line-height:$icon-size-xl + ($btn-xl-v-space * 2);
    &::before{
      font-size:$icon-size-xl;
      height:$icon-size-xl + ($btn-xl-v-space * 2);
      width:$icon-size-xl + ($btn-xl-v-space * 2);
      line-height:$icon-size-xl + ($btn-xl-v-space * 2) !important;
    }
  }
  &.btn--xxl{
    height:$icon-size-xxl + ($btn-xxl-v-space * 2);
    width:$icon-size-xxl + ($btn-xxl-v-space * 2);
    line-height:$icon-size-xxl + ($btn-xxl-v-space * 2);
    &::before{
      font-size:$icon-size-xxl;
      height:$icon-size-xxl + ($btn-xxl-v-space * 2);
      width:$icon-size-xxl + ($btn-xxl-v-space * 2);
      line-height:$icon-size-xxl + ($btn-xxl-v-space * 2) !important;
    }
  }
  &.btn--xxxl{
    height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    width:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    line-height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    &::before{
      font-size:$icon-size-xxxl;
      height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
      width:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
      line-height:$icon-size-xxxl + ($btn-xxxl-v-space * 2) !important;
    }
  }
}

// -- Full Width Buttons
.btn--block,
.btn-inside--blocked a {
  display:flex;
  align-items: center;
  height:100%;
  @include media-breakpoint-only(md){
    // this is just for the frontpage buttons .. we may should move it to global.styles and choose a more specific selector.
    flex-direction: column;
    line-height:1.25em;
    .ico{
      margin-bottom:$space-thin-v !important;
      margin-right:0 !important;
    }
  }
  @include media-breakpoint-up(lg){
    text-align:center;
    justify-content: center;
  }
}

// -- Icons inside buttons - for example <a class="btn ico-zoom-in">Buttontext</a>
.btn::before,
.btn > .ico{
  display: inline-block;
  font-family: $iconfont;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-right:5px;
}

// =============================================================================
//  Icons
// =============================================================================

// Iconfont
@font-face {
  font-family: 'iconfont';
  src:url('../fonts/iconfont/iconfont.eot?-cbfawx');
  src:url('../fonts/iconfont/iconfont.eot?#iefix-cbfawx') format('embedded-opentype'),
  url('../fonts/iconfont/iconfont.woff2?-cbfawx') format('woff2'),
  url('../fonts/iconfont/iconfont.woff?-cbfawx') format('woff'),
  url('../fonts/iconfont/iconfont.ttf?-cbfawx') format('truetype'),
  url('../fonts/iconfont/iconfont.svg?-cbfawx#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// These classes are just for use directly as html class. Dont extend, use the icon mixin instead!
.ico,
.ico-inline{
  @include ico;
}
.ico-seperate{ // Creates an seperate icon out of its own element
  @include ico-replacement;
}
.menu > li > a{
  &.ico-inline{
    &::before{
      font-size:$icon-size-normal; // 16px Menu Icons
      font-weight:normal !important;
      vertical-align:middle;
    }
  }
  &.ico-seperate{
    padding:0 !important;
  }
}

// -- Verfuegbare Icons (see _objects.scss)
@each $name, $ico in $icon-list {
  .ico-#{$name}:before {
    content: $ico;
  }
}

// -- Icon Sizes
.ico--xs{
  &::before{
    font-size:$icon-size-xs;
  }
  &.ico-seperate{
    width:$icon-size-xs;
    height:$icon-size-xs;
    line-height:$icon-size-xs;
  }
}
.ico--normal{
  &::before{
    font-size:$icon-size-normal;
  }
  &.ico-seperate{
    width:$icon-size-normal;
    height:$icon-size-normal;
    line-height:$icon-size-normal;
  }
}
.ico--xl{
  &::before{
    font-size:$icon-size-xl;
  }
  &.ico-seperate{
    width:$icon-size-xl;
    height:$icon-size-xl;
    line-height:$icon-size-xl;
  }
}
.ico--xxl{
  &::before{
    font-size:$icon-size-xxl;
  }
  &.ico-seperate{
    width:$icon-size-xxl;
    height:$icon-size-xxl;
    line-height:$icon-size-xxl;
  }
}
.ico--xxxl{
  &::before{
    font-size:$icon-size-xxxl;
  }
  &.ico-seperate{
    width:$icon-size-xxxl;
    height:$icon-size-xxxl;
    line-height:$icon-size-xxxl;
  }
}


// Icon + Text Combos
.icon-combo{
  text-align:center;
  &__label{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
  }
  &__icon{
    display:inline-block;
    font-size:$icon-size-xxxl;
  }
}

// =============================================================================
//  Box Styles
// =============================================================================

// Default Box Style
.box-style{
  @include box-style;
}
.box-style--info{
  @include box-style-mod('info');
}

// Expandable Blocks
.webks-expandable-container{
  position:relative;
  &.default-hidden:not(.webks-expandable-container--expanded){
    .block-content,
    .webks-expandable-container__content{
      display:none;
    }
  }
  &--expanded{
    .webks-expandable-container__content{
      display:block;
    }
  }
  &__trigger{
    cursor:pointer;
  }
  &__content{
    &:hover{
      z-index:1000 !important; // overlay the full cart flyout
    }
  }
}

// -- Hide them if js isnt loaded / disabled
html:not(.js){
  .block--expandable{
    display:none;
  }
}

// =============================================================================
//  Tiles
// =============================================================================

.tile{
  color:$white;
  text-shadow: 1px 1px 1px $text-shadow-intensity;

  position:relative;
  &__contents{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    z-index:2;
    padding:$thin-grid;
  }
  &__image{
    line-height:0;
  }
  &__overlay-link{
    position:absolute;
    left:0;
    top:0;
    z-index:3;
    width:100%;
    height:100%;
    display:block;
    text-indent:-9000px;
  }
  &__title{
    color:$white;
    margin:0;
    font-size:$font-size-h2;
    line-height:$line-height-small;
  }
  &__price{
    font-size:$font-size-h2;
    line-height:$line-height-small;
    white-space:nowrap;
  }
}

// =============================================================================
//  Cards
// =============================================================================

.card{
  position:relative;
  background:transparent;
  z-index:4;
  margin:0 !important;
  transition-property: border-width, border-color, padding, margin;
  transition-duration: 250ms;
  height:100%; // fill the height if inside a column
  &__image{
    margin-bottom:.85em;
    background-color:$white;
    .field-type-image{
      margin-bottom:0;
    }
  }
  &__header{
    margin-bottom:.5rem;
  }
  &__title{
    font-size:$font-size-h2;
    line-height:$line-height-medium;
    text-align:left;
    text-transform:uppercase;
    margin-top:0;
    margin-bottom:0;
    .first-word{
      display:block;
    }
  }
  &__subline{
    margin-bottom:$space-thin-v;
  }
  &__teaser{
    // Invisible Tables
    table, thead, tbody, tr, td, th{
      background:none !important;
      padding:0 !important;
    }
    .table-responsive{
      overflow: visible;
      background:none;
    }
    table{
      width:100% !important;
    }
  }
  &__overlay-link{
    position:absolute;
    left:0;
    top:0;
    z-index:3;
    width:100%;
    height:100%;
    display:block;
    text-indent:-9000px;
  }
  &--no-image{
    .card__contents{
      display:flex;
      align-items:center;
      height:100%;
    }
    .card__teaser{
      flex-grow:1;
      height:100%;
    }
  }
  &--no-title{
    .card__header{
      display:none;
    }
  }
}

// Style Mobile.de widget inside cards
.card{
  #mobile-drw{
    height:100%;
    > a{
      width:100%;
      height:100%;
    }
  }
}

// =============================================================================
//  Media Objects
// =============================================================================

.media-object{
  @include media-breakpoint-up(sm){
    display:table;
    width:100%;
    display: flex;
  }
  &__image{
    text-align:center;
    line-height:0;
    padding-bottom:$space-thin-v;
    background:$page-background;
    position:relative; // needed for ie11 objectfit polyfill
    @include media-breakpoint-up(sm){
      max-width:35%;
      width:35%;
      padding-bottom:0;
      // display:table-cell; causes errors in ie 11 (this display isn't overridden as a flex child for .. whatever reason)
      vertical-align: top;
    }
    @include media-breakpoint-up(lg){
      width: $media-object-image-width + $thin-grid;
      min-width: $media-object-image-width + $thin-grid;
    }
    .field{
      margin:0;
    }
    a{
      display:block;
      background-color:$image-background-color;
    }
  }
  &__content{
    // Flex fallback
    display:table-cell;
    vertical-align: top;
    // Flexbox
    display: flex;
    flex: 1;
    flex-direction: column;
    @include media-breakpoint-up(sm){
      padding-left:$grid;
    }
  }
  &__title{
    margin-top:0;
    margin-bottom:$space-thin-v / 2;
    color:$highlight;
    a{
      color:$highlight;
    }
  }
  &__read-on{
    padding-top:$grid-vertical / 3;
    margin-top:auto;
    text-align:right;
  }
  &:hover{
    .media-object__title{
      color:$highlight-sec;
      a{
        color:$highlight-sec;
      }
    }
  }
}


// =============================================================================
//  Slideshows
// =============================================================================

// Owl Carousel
@import 'components/owl_carousel';

// =============================================================================
//  Misc Objects
// =============================================================================

// Cutlines
.cutline-top{
  @include cutline('top');
}
.cutline-bottom{
  @include cutline('bottom');
}

// Tags (Tagstyle)
.tagstyle,
.tagstyle ul,
.field-name-field-tags{
  margin:0;
  padding:0;
}
.tagstyle li,
.tagstyle ul li,
.tagstyle .field-item,
.field-name-field-tags .field-item{
  list-style:none;
  margin: 0 .25em .25em 0;
  padding:0;
  display:inline-block;
}
.tagstyle li a,
.tagstyle ul li a,
.tagstyle .field-item a,
.field-name-field-tags .field-item a{
  @include btn('ghost', 'xs');
}

// Subline & Submitted
.subline,
.submitted{
  color: lighten($text-color, 20%);
  font-size: $font-small;
  margin-bottom: .5em;
  line-height:1.15em;
}
.has-subline{ margin-bottom:0; }

// Lists
.checklist {
  list-style: none outside none;
  padding-left:5px;
  li{
    margin-bottom: .75em;
    padding-left: 33px;
    position: relative;
    &::before {
      border: 2px solid $grey-light;
      border-radius: 50%;
      color: $sucess;
      font-family:$iconfont;
      @include get-ico-only('check');
      font-size: $icon-size-normal;
      left: 0;
      line-height: $icon-size-normal;
      padding: 2px;
      position: absolute;
      top: -2px;
    }
  }
}

// Responsive embed videos
iframe{
  // Minimum fix to prevent crashing the layout
  max-width:100%;
}
.embed-responsive:not(.responsive-iframe-wrapper--unknown-type),
.responsive-iframe-wrapper:not(.responsive-iframe-wrapper--unknown-type) {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  &-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9,
.responsive-iframe-wrapper--video,
.responsive-iframe-wrapper--map { // this second class is set by our iframe detection script
  padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

// Unknown type responsive iframe - just 100% width
// Now solved with :not selector - see code above
// .responsive-iframe-wrapper--unknown-type{
//   height:auto;
//   iframe,
//   embed,
//   object,
//   video {
//     position:static;
//     height: initial;
//   }
// }
.responsive-iframe-wrapper--unknown-type{
  iframe{
    width:100%;
  }
}

// Maps

// Image Maps (DSGVO conform replacement for embeded maps)
.image-map{
  position:relative;
  &__map{
    display:block;
    line-height:0;
    height:100%;
    img{
      object-fit:cover;
      object-position: center center;
      width:100%;
      height:100%;
      max-width:none;
      transition-duration:80ms;
      transition-property: filter;
      filter: grayscale(50%);
      @include media-breakpoint-down(md) {
        width: 300%;
        height: 500px;
        transform: translateX(-24%);
      }
    }
  }
  &__direction{
    position: absolute;
    max-width: 40%;
    bottom: 1rem;
    left: 1rem;
    margin:0;
    opacity: .7;
    line-height: 1.1;
    font-size: $small-font-size;
  }
  &__source{
    display:block;
    position:absolute;
    right:0;
    bottom:0;
    max-width:65%;
    line-height:1.1;
    opacity:.65;
    color: black !important;
    // ensure its always readable
    text-shadow:1px 1px 0 white;
    background-color: rgba(255,255,255,.75);
    padding:.25em .5em;
    border-top-left-radius: 3px;
    a{
      color: currentColor !important;
    }
    &:hover{
      opacity:1;
    }
  }
  &:hover{
    .image-map__map{
      img{
        filter: grayscale(0%);
      }
    }
  }
}

.pane--custom-directions-map{
  .pane-title{
    margin-top:0;
    font-size:$font-normal;
  }
}
.custom-directions-map{
  overflow:hidden; // clearfix
  margin-bottom:$grid-vertical;
  &__form-wrapper{
    // Wrapper
  }
  &__form{
    label{
      display:block;
    }
  }
}

// =============================================================================
//  Tooltips (via Popper.js > https://popper.js.org/documentation.html)
// =============================================================================

.popper{
  background:$tooltip-color;
  color:$tooltip-text-color;
  padding:$thin-grid;
  width: 150px;
  text-align:center;
  border-radius:$border-radius-small;
  box-shadow:$shadow-small;
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .popper__arrow {
      border-width: 5px 5px 0;
      border-color: $tooltip-color $transparent $transparent;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
    margin-top: 5px;
    .popper__arrow {
      border-width: 0 5px 5px;
      border-color: $transparent $transparent $tooltip-color;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="right"] {
    margin-left: 5px;
    .popper__arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent $tooltip-color transparent transparent;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[x-placement^="left"] {
    margin-right: 5px;
    .popper__arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $tooltip-color;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  a{
    color:$tooltip-text-color;
  }
  .popper__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }
}

// =============================================================================
//  jQuery.UI
// =============================================================================

// uncomment if needed
// @import 'components/jquery_ui';

// =============================================================================
//  Messages
// =============================================================================

// Left and right margin are set by the global gutter width
.message,
div.messages {
  @include message;
}

// Message Types
.message--info,
div.info {
  // @include message-mod(info);
  background-color: $grey-lighter;
  border-color: $grey-light;
  color: $grey-darker;
}
.message--success,
div.status {
  @include message-mod(success);
}
.message--warning,
div.warning {
  @include message-mod(warning);
}
.message--error,
div.error {
  @include message-mod(error);
}

// -- Other warning & error elements
tr.warning { color:$message-warning-text; }
tr.error { color:$message-error-text; }

// -- Form Errors
input[type="text"].error,
.form-text.error,
textarea.error,
.error {
  border-color:$message-error-text;
}

// Unpublished nodes
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: $message-info-text;
  font-size: 75px;
  font-weight: $font-weight-bold;
  height: 0;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
}

// EU Cookie Compliance Message (if active) - comment in if needed
// @import 'components/eu_cookie_compliance';
